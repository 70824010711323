.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(250, 242, 231);
  overflow: hidden;
}
.loader-wrap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.loader-wrap h3 {
  font-size: 20px;
  font-weight: 100;
  font-family: "panchang";
}
.counter {
  position: absolute;
  top: 56%;
}
.loader-container p {
  font-size: 18px;
  font-family: "panchang", sans-serif;
}
.loader-bar {
  position: absolute;
  height: 5px;
  width: 100vw;
  background-color: rgb(17, 17, 17);
  border-radius: 100px;
  top: 55%;
}
@media screen and (maxx-width: 400px) {
  .loader-bar {
    top: 56%;
  }
  .counter {
    top: 57%;
  }
}
