.contact-btn {
  position: relative;
  width: 400px;
  height: 400px;
  right: 200px;
  border-right: 1px solid rgb(131, 131, 131);
  border-bottom: 1px solid rgb(131, 131, 131);
  border-left: 1px solid rgb(131, 131, 131);
  border-top: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 884px) {
  .contact-btn {
    right: 80px;
    width: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 664px) {
  .contact-btn {
    right: 0;
    width: 250px;
    height: 250px;
  }
}
.contact-btn h3 {
  transform: rotate(-45deg);
  overflow: hidden;
}

.contact-btn a {
  text-decoration: none;
  color: rgba(255, 254, 210, 0.575);
  font-size: clamp(13px, 10px + 2vw, 27px);
  font-weight: 100;
  letter-spacing: 4px;
}
.contact-btn a:hover {
  color: white;
}
