.about-text {
  color: rgb(190, 190, 190);
  line-height: 38px;
  letter-spacing: 1.5px;
  font-size: 32px;
  font-weight: 600;
  padding-right: 40px;
}
.about-text p {
  width: 80%;
}
@media screen and (max-width: 560px) {
  .about-text p {
    width: 100%;
  }
  .about-text {
    padding-right: 0;
    font-size: 20px;
    line-height: 22px;
  }
}
